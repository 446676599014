import React, { useEffect, useState } from "react";

function getSeatsTaken({ countableNumbers, totalHours, progressHours }) {
  // split in two parts
  const firstPartTotalHours = Math.floor(totalHours * 0.75);

  // im letzten viertel der Zeitspanne (bei einem Monat in der letzten Woche) gehen 40% der plätze weg
  // in den vierteln davor gehen jeweils 20% weg
  const quarterHours = totalHours / 4;
  const perHourFirstQuarters = (countableNumbers * 0.6) / 3 / quarterHours;
  const perHourLastQuarter = (countableNumbers * 0.4) / quarterHours;

  const hoursInFirstPart = Math.min(progressHours, firstPartTotalHours);
  const firstPartNumbers = hoursInFirstPart * perHourFirstQuarters;

  const hoursInSecondPart = Math.max(progressHours - firstPartTotalHours, 0);
  const secondPartNumbers = hoursInSecondPart * perHourLastQuarter;

  const seatsTaken = Math.round(firstPartNumbers + secondPartNumbers);

  return seatsTaken;
}

// for (let i = 1; i <= 24; i++) {
//   getSeatsTaken({
//     countableNumbers: 200,
//     totalHours: 24,
//     progressHours: i,
//   })
// }

export default function Scarcity({ scarcity, type }) {
  const [scarcityNumber, setScarcityNumber] = useState(null);
  useEffect(() => {
    if (!scarcity) return;
    const countableNumbers = scarcity.totalCount - scarcity.lastNumber;
    const totalTime = scarcity.endDate - scarcity.startDate;
    const totalHours = totalTime / 60 / 60;
    const now = Math.round(Date.now() / 1e3);
    const progressHours = (now - scarcity.startDate) / 60 / 60;

    // console.info({
    //   endDate: scarcity.endDate,
    //   endDateDate: new Date(Number(scarcity.endDate) * 1e3),
    //   startDate: scarcity.startDate,
    //   startDatDate: new Date(Number(scarcity.startDate) * 1e3),
    //   nowDate: new Date(Number(now) * 1e3),
    //   now,
    //   totalTime,
    // })

    const seatsTaken = getSeatsTaken({
      countableNumbers,
      totalHours,
      progressHours,
    });

    const calculatedNumber = Math.round(scarcity.totalCount - seatsTaken);
    setScarcityNumber(calculatedNumber);
  }, []);

  // styles for home hero
  const styles = {
    pointerEvents: "none",
    textAlign: "center",
    maxWidth: "80%",
  };

  // styles for card
  const buttonTypeStyles =
    type === "button"
      ? {
          maxWidth: "120%",
          position: "relative",
          bottom: 5,
          left: "50%",
          transform: "translateX(-50%)",
          whiteSpace: "nowrap",
          width: "calc(100% + 14px)",
          display: "block",
          background: "#CDD8DD",
          fontWeight: "normal",
          padding: "73px 15px 5px",
          borderRadius: "5px",
          marginBottom: -12,
          zIndex: 0,
        }
      : {};

  return scarcity && scarcityNumber ? (
    <span style={{ ...styles, ...buttonTypeStyles }}>
      {scarcity.textBefore} <strong>{scarcityNumber}</strong>{" "}
      {scarcity.textAfter}{" "}
    </span>
  ) : null;
}
