import React from 'react'
import PropTypes from 'prop-types'
import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import { colors } from 'components/Layout/variables'

import Headline from 'components/Headline'

const StyledTitleSlogan = styled('div')(
  {
    position: 'relative',
    whiteSpace: 'pre-wrap',
  },
  ({ line }) =>
    line && {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%)',
        height: 6,
        width: 100,
        opacity: 1,
        backgroundColor: colors.green,
      },
    }
)

export const TitleSlogan = ({ titleSlogan, line, titleFont, titlefontsize,titlefontsizeMobile }) => (
  <StyledTitleSlogan line={line}>
    <Headline
      titleFont={titleFont}
      headlinefontsize={titlefontsize}
      headlinefontsizeMobile={titlefontsizeMobile}
      element="h1"
      lineHeight={1}
      bold
      textTransform="none"
    >
      {titleSlogan}
    </Headline>
  </StyledTitleSlogan>
)

TitleSlogan.propTypes = {
  titleSlogan: PropTypes.string,
}

TitleSlogan.defaultProps = {
  titleSlogan: null,
}

export default TitleSlogan
