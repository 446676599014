import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

export const ReactPlayerStyled = styled("div")(
  {
    opacity: 1,
    transition: "opacity 200ms ease-out"
  },
  // When children are given and we are on mobile devices => do not display the parent items
  props =>
    props.menuOpen && {
      opacity: 0
    }
);

export const PlayerWrapperStyled = styled("div")(
  {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    transition: "opacity ease-out 500ms",
    objectFit: "cover",

    video: {
      minWidth: "100vw",
      minHeight: "100vh",
      width: "auto !important",
      height: "auto !important",
      /* Center the video */
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"
    }
  },
  props => ({
    opacity: props.videoready ? 1 : 0
  })
);
