import React from 'react'
import useTimeDifference from 'utils/useTimeDifference'

import {
  OfferBadgeImageStyled,
  OfferBadgeLinkStyled,
  OfferBadgeWrapStyled,
} from './OfferBadgeStyled'

export default function OfferBadge({ offerBadge, mobile, layout }) {
  const offerBadgeImage = offerBadge.offerBadgeImage[0] || null
  const offerBadgeStart = offerBadge.offerBadgeStart
  const offerBadgeEnd = offerBadge.offerBadgeEnd
  const fullUri =
    offerBadge.offerBadgeLink?.entry?.fullUri ||
    offerBadge.offerBadgeLink?.url ||
    ''
  const timeToStart = useTimeDifference(offerBadgeStart)
  const timeToEnd = useTimeDifference(offerBadgeEnd)
  const currentlyActive = timeToStart < 0 && timeToEnd > 0

  return (
    (offerBadgeImage && currentlyActive && (
      <OfferBadgeWrapStyled
        mobile={mobile}
        style={{
          maxWidth: parseFloat(offerBadgeImage.width),
        }}>
        <OfferBadgeLinkStyled mobile={mobile} layout={layout} to={fullUri}>
          <OfferBadgeImageStyled
            fluid={offerBadgeImage}
            aspectRatio={offerBadgeImage.width / offerBadgeImage.height}
            objectFit="contain"
          />
        </OfferBadgeLinkStyled>
      </OfferBadgeWrapStyled>
    )) ||
    null
  )
}
