import React, { useEffect } from "react";
import Image from "components/Image";
import { ImageWrapperStyled } from "./styles";
import { useWindowSize } from "~/utils/hooks"

export default function FullscreenImage({ image, mobileAlternative }) {
  const {width} = useWindowSize()

  return (
    <div>
      <ImageWrapperStyled>
        <Image
          fluid={width > 768 ? image : mobileAlternative}
          args={{ maxWidth: 1920 }}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        />
      </ImageWrapperStyled>
    </div>
  );
}
