import { styled } from "utils/emotion";
export const ImageWrapperStyled = styled("div")({
  pointerEvents: "none",
  backfaceVisibility: "hidden",
  zIndex: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
});
