import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import Text from 'components/Text';

export const TextStyled = styled(Text)({
  whiteSpace: 'pre-line',
  p: {
    lineHeight: 1.3
  },
});

export const ButtonWrapperStyled = styled('div')({
  marginTop: 25,
  zIndex: 1,
  maxWidth: 900,
})

export const OfferBadgeContainer = styled('div')(
  {
    width: '100%',
    maxWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ({ layout }) =>
    layout === 'bottom' && {
      // quickfix "bottom" is now outside
      position: 'absolute',
      top: 100,
      right: 20,

      [media(0, 'mobile')]: {
        position: 'relative',
        maxWidth: '190px',
        top: 0,
        right: -130,
        marginTop: -20,
        marginBottom: -20,
      },
    }
)

export const ContentStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '150px 30px',
  textAlign: 'center',
  height: '100%',
  minHeight: '100vh',

  a: {
    pointerEvents: 'all',
  },
})
