import React, { useState, useEffect } from 'react'

import ReactPlayer from 'react-player/lazy'
import VideoMp4 from '../../assets/evofitness-1_small.mp4'

import { PlayerWrapperStyled, ReactPlayerStyled } from './styles'

export default function FullscreenVideo({ menuOpen, videoUrl }) {
  const [videoReady, setVideoReady] = useState(false)
  const [videoAvailable, setVideoAvailable] = useState(false)

  /**
   * Event:
   * Video ready to play.
   */
  const handleVideoReady = () => {
    setVideoReady(true)
  }

  const updateDimensions = () => {
    if (typeof window !== 'undefined') {
      const wideEnough = window.outerWidth > 1024
      setVideoAvailable(wideEnough)
    }
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', () => updateDimensions())

    return () => {
      setVideoAvailable(false)
      window.removeEventListener('resize', () => updateDimensions())
    }
  }, [])

  return (
    <PlayerWrapperStyled videoready={videoReady}>
      {videoAvailable && (
        <ReactPlayerStyled menuOpen={menuOpen}>
          <ReactPlayer
            onReady={handleVideoReady}
            width="100vw"
            height="100vh"
            loop
            muted
            url={videoUrl || VideoMp4}
            playing
          />
        </ReactPlayerStyled>
      )}
    </PlayerWrapperStyled>
  )
}
