import React from "react";
import { graphql } from "gatsby";
import Wrap from "components/Wrap";
import Overlay from "components/Overlay";
import FullscreenVideo from "components/FullscreenVideo";
import FullscreenImage from "components/FullscreenImage";
import Button from "components/Button";
import TitleSlogan from "components/TitleSlogan";
import OfferBadge from "components/OfferBadge";
import Scarcity from "components/Scarcity";
import Redactor from "components/Redactor";
import Fade from "react-reveal/Fade";

import {
  ContentStyled,
  ButtonWrapperStyled,
  OfferBadgeContainer,
  TextStyled,
} from "./styles";

const HomeHero = ({ pageBuilder }) => {
  const {
    titleSlogan,
    buttonSet,
    offerBadge,
    background,
    mobileBackground,
    titleFont,
    titleFontLine2,
    titleLine2,
    titleSize,
    subtitle,
  } = pageBuilder;

  const titleFontSize =
    titleSize === "s"
      ? 40
      : titleSize === "m"
      ? 60
      : titleSize === "l"
      ? 80
      : titleSize === "xl"
      ? 100
      : 60;

  const titleFontSizeMobile =
    titleSize === "s"
      ? 25
      : titleSize === "m"
      ? 30
      : titleSize === "l"
      ? 40
      : titleSize === "xl"
      ? 50
      : 30;

  return (
    <Wrap overflow="hidden" minHeight="100vh">
      {background?.[0]?.backgroundImage && (
        <FullscreenImage
          image={background[0].backgroundImage[0]}
          mobileAlternative={mobileBackground?.[0]}
        />
      )}
      {background?.[0]?.backgroundVideo?.[0]?.url && (
        <FullscreenVideo videoUrl={background[0].backgroundVideo[0].url} />
      )}
      <Overlay />
      <ContentStyled layout={offerBadge?.[0]?.offerBadgeLayout || null}>
        {offerBadge && offerBadge?.[0]?.offerBadgeImage?.[0] && (
          <OfferBadgeContainer
            layout={offerBadge?.[0]?.offerBadgeLayout || null}
          >
            <OfferBadge
              offerBadge={offerBadge[0]}
              mobile={false}
              layout={offerBadge?.[0].offerBadgeLayout}
            />
            <Scarcity scarcity={offerBadge?.[0]?.scarcity?.[0]} />
          </OfferBadgeContainer>
        )}
        <div style={{ marginTop: 30, maxWidth: 1000 }}>
          {titleSlogan && (
            <Fade bottom distance="10px" delay={400}>
              <TitleSlogan
                titleFont={titleFont}
                titleSlogan={titleSlogan}
                titlefontsize={titleFontSize}
                titlefontsizeMobile={titleFontSizeMobile}
                line={offerBadge?.offerBadgeLayout === "top"}
              />
            </Fade>
          )}
          {titleLine2 && (
            <Fade bottom distance="10px" delay={400}>
              <TitleSlogan
                titleFont={titleFontLine2}
                titleSlogan={titleLine2}
                titlefontsize={titleFontSize}
                titlefontsizeMobile={titleFontSizeMobile}
                line={offerBadge?.offerBadgeLayout === "top"}
              />
            </Fade>
          )}
        </div>
        {subtitle?.content && (
          <Fade bottom distance="10px" delay={600}>
            <TextStyled element="div" textAlign="center">
              <Redactor content={subtitle.content} parseType="content" />
            </TextStyled>
          </Fade>
        )}
        {buttonSet?.length > 0 && (
          <Fade bottom distance="10px" delay={1000}>
            <ButtonWrapperStyled>
              {buttonSet.map(({ buttonTheme, buttonLink, buttonFilled }) => (
                <Button
                  themeName={buttonTheme}
                  key={buttonLink?.entry?.title}
                  filled={buttonFilled}
                  target={buttonLink?.target}
                  to={
                    buttonLink?.url ||
                    buttonLink.entry?.fullUri ||
                    buttonLink.entry?.url
                  }
                  textAlign="center"
                >
                  {buttonLink?.text || buttonLink?.entry?.title}
                </Button>
              ))}
            </ButtonWrapperStyled>
          </Fade>
        )}
      </ContentStyled>
    </Wrap>
  );
};

export default HomeHero;

export const query = graphql`
  fragment PageBuilderHomeHeroQuery on Craft_PageBuilderHomeHero {
    titleSlogan
    titleLine2
    titleSize
    buttonSet {
      buttonLink {
        url
        text
        entry {
          title
          fullUri
        }
      }
      buttonTheme
      buttonFilled
    }
    mobileBackground {
      ...CraftImageFluid
    }
    titleFont
    titleFontLine2
    subtitle {
      content
    }
    background {
      backgroundVideo {
        url
      }
      backgroundImage {
        ...CraftImageFluid
      }
    }
    offerBadge {
      offerBadgeStart
      offerBadgeEnd
      offerBadgeLayout
      offerBadgeImage {
        ...CraftImageFluid
      }
      offerBadgeLink {
        url
        entry {
          title
          fullUri
        }
      }
      scarcity {
        ... on Craft_ScarcityScarcity {
          textBefore
          textAfter
          totalCount
          lastNumber
          startDate
          endDate
        }
      }
    }
  }
`;
