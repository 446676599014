import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import Link from 'components/Link'
import Image from 'components/Image'

export const OfferBadgeImageStyled = styled(Image)({
  //   width: "100%",
  transition: 'transform 100ms ease-out',

  '&:hover': {
    // transform: "scale(1.03)",
  },
})

export const OfferBadgeLinkStyled = styled(Link)({
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  pointerEvents: 'all',
  // marginBottom: 30,

  [media(0, 'mobile')]: {},
})

export const OfferBadgeWrapStyled = styled('div')({
  pointerEvents: 'none',
  width: '100%',
})
